<template>
  <v-container class="ma-0 pa-0">
    <div class="spectrum">
      <template v-if="playingSong(Lget(song, 'id', null))">
        <div class="ml-2">
          <!-- Credits: https://codepen.io/sidjain26/pen/pyRvGM/license -->
          <div class="ml-1 mt-2 equaliser-container">
            <ol class="equaliser-column">
              <li class="colour-bar"></li>
            </ol>
            <ol class="equaliser-column">
              <li class="colour-bar"></li>
            </ol>
            <ol class="equaliser-column">
              <li class="colour-bar"></li>
            </ol>
            <ol class="equaliser-column">
              <li class="colour-bar"></li>
            </ol>
            <ol class="equaliser-column">
              <li class="colour-bar"></li>
            </ol>
          </div>
          <v-icon class="playpause ml-2" @click.native.stop="$emit('playpause',song)">{{ mdiPause }}</v-icon>
        </div>
      </template>
      <template v-else>
        <div class="btnimg" width="24" height="24" :class="{ playing: isCurrentTrack(Lget(song, 'id', null))}">{{ trackNum !== null ? trackNum : Lget(song, 'trackNum', null) }}</div>
        <v-icon class="playpause ml-2" @click.native.stop="$emit('playpause',song)">{{ mdiPlay }}</v-icon>
      </template>
    </div>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import { mdiPlay, mdiPause } from '@mdi/js'

export default {
  props: {
    song: Object,
    trackNum: {
      type: Number,
      default: null
    }
  },

  data: () => ({
    mdiPause,
    mdiPlay
  }),

  computed: {
    ...mapGetters('player', ['currentSong','isPlaying']),
  },

  methods: {
    ...mapActions('player', ['play']),

    Lget: Lget,

    isCurrentTrack(id) {
      return Boolean(Lget(this, 'currentSong.id', null) === id)
    },

    playingSong(id) {
      return Boolean(Lget(this, 'currentSong.id', null) === id) && this.isPlaying
    }
  }
}
</script>

<style scoped>
.playing {
  color: var(--v-primary-base) !important;
}
.spectrum {
  width: 24px;
  height: 24px;
  position: relative;
  display: inline-block;
}
.spectrum .playpause {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
}
.spectrum:hover .playpause {
  display: inline;
}
.spectrum:hover .btnimg {
  display: none;
}

.equaliser-container {
  height: 10px;
  width: 20px;
  margin: 0 0;
  padding: 0 0 0 0;
  position: relative;
}

.colour-bar {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 10px;
  background: rgb(30,185,128,1);
}

.equaliser-column {
  width: 2px;
  float: left;
  margin: 0 1px 0 0;
  padding: 0;
  height: 10px;
  position: relative;
  list-style-type: none;
}

.equaliser-column:nth-child(1) .colour-bar {
  animation: color-bar 2s 1s ease-out alternate infinite;
}

.equaliser-column:nth-child(2) .colour-bar {
  animation: color-bar 2s 0.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(3) .colour-bar {
  animation: color-bar 2s 1.5s ease-out alternate infinite;
}

.equaliser-column:nth-child(4) .colour-bar {
  animation: color-bar 2s 0.25s ease-out alternate infinite;
}

.equaliser-column:nth-child(5) .colour-bar {
  animation: color-bar 2s 2s ease-out alternate infinite;
}

.equaliser-column:last-child {
  margin-right: 0;
}

@keyframes color-bar {
  0% {
    height: 1px;
    background: rgb(30,185,128,1);
  }
  10% {
    height: 3px;
    background: rgb(30,185,128,1);
  }
  20% {
    height: 5px;
    background: rgb(30,185,128,1);
  }
  30% {
    height: 2px;
    background: rgb(30,185,128,1);
  }
  40% {
    height: 7px;
    background: rgb(30,185,128,1);
  }
  50% {
    height: 9px;
    background: rgb(30,185,128,1);
  }
  60% {
    height: 3px;
    background: rgb(30,185,128,1);
  }
  70% {
    height: 8px;
    background: rgb(30,185,128,1);
  }
  80% {
    height: 5px;
    background: rgb(30,185,128,1);
  }
  90% {
    height: 3px;
    background: rgb(30,185,128,1);
  }
  100% {
    height: 1px;
    background: rgb(30,185,128,1);
  }
}
</style>