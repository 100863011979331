<template>
  <v-container class="ma-0 pa-0">
    <v-btn v-if="isHovering" icon @click.native.stop="showItemMenu" class="menu-button">
      <v-icon v-if="useVerticalDots">{{ mdiDotsVertical }}</v-icon>
      <v-icon v-else>{{ mdiDotsHorizontal }}</v-icon>
    </v-btn>

    <v-menu
      v-model="itemMenuShown"
      :position-x="itemX"
      :position-y="itemY"
      absolute
      offset-y
      max-width="300"
      ref="itemMenu">
      <v-list>
        <v-list-item @click="addSong">
          <v-list-item-title>{{ $t('queue.add-to-queue') }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="!isArtist" :to="{ name: 'Artist', params: {id: Lget(song, 'album.artist.id', null)} }">
          <v-list-item-title>{{ $t('artist.go-to') }}</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="!isAlbum" :to="{ name: 'Album', params: {id: Lget(song, 'album.id', null)} }">
          <v-list-item-title>{{ $t('album.go-to') }}</v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item v-if="isPlaylist && isPlaylistOwner" @click="removeFromPlaylist">
          <v-list-item-title>{{ $t('playlist.remove-song') }}</v-list-item-title>
        </v-list-item>
        <v-menu open-on-hover left offset-x max-height="700" max-width="300" ref="playlistMenu" class="scrollableMenu">
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-title>{{ $t('playlist.add-to') }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon>{{ mdiChevronRight }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item @click="addToPlaylist(null)">
              <v-list-item-title>{{ $t('playlist.add-to-new') }}</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item v-for="(menuPlaylist, idx) in filteredPlaylist" :key="idx" @click="addToPlaylist(menuPlaylist)" :disabled="isPlaylist && playlist.id === menuPlaylist.id">
              <v-list-item-title>{{ menuPlaylist.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list>
    </v-menu>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { mdiDotsHorizontal, mdiDotsVertical, mdiChevronRight } from '@mdi/js'
import Lmerge from 'lodash/merge'
import LcloneDeep from 'lodash/cloneDeep'
import Lfilter from 'lodash/filter'
import Lget from 'lodash/get'

export default {
  props: {
    isHovering: {
      type: Boolean,
      default: false
    },
    song: {
      type: Object,
      default: () => {}
    },
    isPlaylist: {
      type: Boolean,
      default: false
    },
    isAlbum: {
      type: Boolean,
      default: false
    },
    isArtist: {
      type: Boolean,
      default: false
    },
    isPlaylistOwner: {
      type: Boolean,
      default: false
    },
    useVerticalDots: {
      type: Boolean,
      default: false
    }
  },

  watch: {
    itemMenuShown: function (newV, oldv) {
      if (!newV) {
        this.$emit('closed')
      }
    }
  },

  data: () => ({
    mdiDotsHorizontal,
    mdiDotsVertical,
    mdiChevronRight,
    itemMenuShown: false,
    itemX: 0,
    itemY: 0
  }),

  computed: {
    ...mapGetters('playlists', ['playlists', 'playlist']),
    ...mapGetters('users', ['user']),

    filteredPlaylist() {
      return Lfilter(this.playlists, (o) => {
        return Lget(o, 'userId', null) === Lget(this, 'user.id', null)
      })
    }
  },

  methods: {
    ...mapActions('player', ['addToQueue']),
    ...mapActions('playlists', ['removePlaylistTrack', 'addPlaylistTrack', 'createPlaylistWithTrack']),

    Lget: Lget,

    addSong() {
      const addedSong = Lmerge(
        LcloneDeep(this.song),
        { source: { isQueued: true, displayName: this.song.album.albumName } }
      )
      this.addToQueue(addedSong)
    },

    async addToPlaylist(playlist) {
      if (!playlist) {
        await this.createPlaylistWithTrack({ track: this.song })
      } else {
        await this.addPlaylistTrack({ id: playlist.id, track: this.song })
      }
      this.itemMenuShown = false
    },

    async removeFromPlaylist() {
      const playlistId = Lget(this,'song.PlaylistItem.playlistId', null)
      const playlistItemId = Lget(this,'song.PlaylistItem.id', null)
      if (!playlistId) {
        this.$log.fatal('SongItemMenu - removeFromPlaylist: No playlist id!')
        return
      }
      await this.removePlaylistTrack({ id: playlistId, itemId: playlistItemId })
    },

    showItemMenu(e) {
      e.preventDefault()
      this.$emit('clicked')
      this.itemMenuShown = false
      this.itemX = e.clientX
      this.itemY = e.clientY
      this.$nextTick(() => {
        this.itemMenuShown = true
      })
    }
  }
}
</script>

<style scoped>
  .theme--dark.v-divider {
    border-color: rgba(255, 255, 255, 0.7);
  }
  .menu-button:before {
    background-color: transparent !important;
  }
  .v-list.scrollableMenu {
    height: 100%;
    overflow-y: auto;
    background-color: var(--v-background-base);;
  }
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--v-cardbackground-base); 
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3); 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgba(255, 255, 255, 0.1); 
  }
</style>