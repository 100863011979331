<template>
  <v-list class="transparent-background">
    <v-list-item class="px-2" two-line dense v-for="(song, idx) in songs" :key="idx" @click="$emit('playpause', song)">
      <v-list-item-avatar class="py-0 my-2" tile left :size="imageSize">
        <v-img :height="imageSize" :width="imageSize" :src="Lget(song, 'album.imageSrc', '')" />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="subtitle-1" :class="{ 'playing': Lget(currentSong, 'id', null) === Lget(song, 'id', null) }">{{ Lget(song, 'songName', '') }}</v-list-item-title>
        <v-list-item-subtitle>{{ Lget(song, 'artist.artistName', '') }}</v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action class="mr-n3 mt-1">
        <SongItemMenu
          :isArtist="mode==='artist'"
          :isPlaylist="['playlist', 'playlist-owner'].includes(mode)"
          :isPlaylistOwner="mode==='playlist-owner'"
          :isAlbum="mode==='album'" 
          :song="song"
          :isHovering="true"
          :useVerticalDots="true"
        >
        </SongItemMenu>
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import { mapGetters } from 'vuex'
import Lget from 'lodash/get'
import SongItemMenu from '@/components/SongItemMenu'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      required: true
    }
  },

  components: {
    SongItemMenu
  },

  computed: {
    ...mapGetters('player', ['currentSong']),

    imageSize() {
      return this.$vuetify.breakpoint.mobile ? 48 : 80
    }
  },

  methods: {
    Lget: Lget,

    isPlayingClass(id) {
      return Lget(this, 'currentSong.id', null) === id ? 'playing' : ''
    }
  }
}
</script>

<style scoped>
  .playing {
    color: var(--v-primary-base) !important;
  }
</style>