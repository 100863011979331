<template>
  <v-card class="mb-6 transparent-background" height="320">
    <v-row class="ml-2">
      <v-card class="d-inline-flex transparent-background">
        <v-img class="item-image" min-height="250" min-width="250" contain height="250" width="250" :src="albumArt">
          <template v-slot:placeholder>
            <v-sheet color="grey lighten-4" class="fill-height">
              <v-skeleton-loader class="mx-auto" type="image"></v-skeleton-loader>
            </v-sheet>
          </template>
        </v-img>
        <v-card class="ml-4 transparent-background" min-height="250" fill-height>
          <div class="titleTextContainer">
            <div class="title">{{ type }}</div>
            <div :class="`mt-n3 mb-n2 ${titleClass}`">{{ title }}</div>
            <template v-if="subtitleLink">
              <v-row class="ml-1 pb-3" d-flex flex-row justify-start align-center>
                <div class="body-2 mr-1"><strong><router-link class="link" :to="subtitleLink.to">{{ subtitleLink.label }}</router-link></strong></div> <div class="body-2" v-html="subtitle"></div>
              </v-row>
            </template>
            <template v-else>
              <div class="body-2" v-html="subtitle"></div>
            </template>
          </div>
        </v-card>
      </v-card>
    </v-row>
    <v-row>
      <div class="d-flex flex-row ml-4 mt-8">
        <v-btn rounded color="primary" @click="$emit('play')" data-test-id="largeHeaderPlayBtn">{{ $t("buttons.playAll") }}</v-btn>
        <LikeIcon v-if="likeData" :large="true" class="ml-6" :id="likeData.id" :type="likeData.type" :likes="likeData.like"></LikeIcon>
        <slot></slot>
      </div>
    </v-row>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Lget from 'lodash/get'
import LisEmpty from 'lodash/isEmpty'
import LikeIcon from '@/components/LikeIcon'

export default {
  props: {
    imageSrc: {
      type: String
    },
    title: {
      type: String
    },
    subtitleLink: {
      type: Object
    },
    subtitle: {
      type: String
    },
    type: {
      type: String
    },
    likeData: {
      type: Object
    }
  },

  components: {
    LikeIcon
  },

  computed: {
    albumArt() {
      const cover = Lget(this, 'imageSrc', null)
      const emptyCover = require("@/assets/images/album.png")
      return LisEmpty(cover) ? emptyCover : cover
    },

    titleClass() {
      const nameLength = Lget(this, 'title.length', 0)
      let nameClass = 'titleLarge'
      if (this.$vuetify.breakpoint.xl)
        switch(true) {
          case (nameLength > 50):
            nameClass = 'titleSmall'
            break;
          case (nameLength > 30):
            nameClass = 'titleMedium'
            break;
          default:
            nameClass = 'titleLarge'
        }
      if (this.$vuetify.breakpoint.lg)
        switch(true) {
          case (nameLength > 30):
            nameClass = 'titleSmall'
            break;
          case (nameLength > 20):
            nameClass = 'titleMedium'
            break;
          default:
            nameClass = 'titleLarge'
        }
      if (this.$vuetify.breakpoint.md)
        switch(true) {
          case (nameLength > 20):
            nameClass = 'titleSmall'
            break;
          case (nameLength > 10):
            nameClass = 'titleMedium'
            break;
          default:
            nameClass = 'titleLarge'
        }
      return `titleText ${nameClass}`
    },

  },

  methods: {
    Lget: Lget,
  }
}
</script>

<style lang="scss" scoped>
::v-deep .v-skeleton-loader.v-skeleton-loader--is-loading {
  .v-skeleton-loader__image {
    height: 100%;
  }
}
</style>

<style scoped>
.fixed-height {
  min-height: 200px !important;
  min-width: 200px !important;
}
.titleTextContainer {
  position: absolute;
  bottom: 0;
  min-width: 450px;
}
.titleText {
  font-weight: 300;
  letter-spacing: initial !important;
  font-family: "Anton", sans-serif !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}
.titleLarge {
  font-size: 6rem !important;
}
.titleMedium {
  padding-top: 2px;
  font-size: 4rem !important;
  padding-bottom: 2px;
}
.titleSmall {
  padding-top: 6px;
  font-size: 2rem !important;
  padding-bottom: 6px;
}
.item-image {
  filter: drop-shadow(0 0 0.75rem rgba(0,0,0,0.7));
}
a.link {
  text-decoration: none;
  color: var(--v-textcolor-base);
  margin-bottom: 1px;
}
</style>