<template>
  <div class="wrapper ma-0 pa-0">
  <v-simple-table class="song-table transparent-background">
    <thead class="sticky" v-if="['playlist', 'playlist-owner', 'album', 'likedsongs'].includes(mode)">
      <tr>
        <th class="text-right">
          #
        </th>
        <th class="text-left">
          {{ $t('album.title') }}
        </th>
        <th v-if="['playlist', 'playlist-owner', 'likedsongs'].includes(mode)" class="text-left">
          {{ $t('album.label') }}
        </th>
        <th v-if="['playlist', 'playlist-owner', 'likedsongs'].includes(mode)" class="text-left">
          {{ $t('playlist.added') }}
        </th>
        <th v-if="mode==='album'" class="text-right">
          {{ $t('album.plays') }}
        </th>
        <th>
        </th>
        <th class="text-right">
          <v-icon small>{{ mdiClockOutline }}</v-icon>
        </th>
        <th >
        </th>
      </tr>
    </thead>

    <draggable :disabled="mode!=='playlist-owner'" :forceFallback="true" v-model="sortableSongs" tag="tbody">
      <tr v-for="(song,idx) in sortableSongs" :key="idx" @dblclick="$emit('playpause', song)" @mouseover="hover = idx" @mouseleave="hover = null">
        <td class="text-right" width="56px">
          <TrackNum :song="song" :trackNum="idx + startTrackNumAt" @playpause="$emit('playpause', song)"/>
        </td>
        <td width="auto">
          <div class="d-flex flex-row">
            <div v-if="mode!=='album'" class="cover-container">
              <v-img :src="Lget(song, 'album.imageSrc', '')" height="48px" width="48px"></v-img>
            </div>
            <v-list-item-content :class="{'py-1': mode==='album', 'py-2': mode!=='album' }">
              <v-list-item-title class="subtitle-1 mb-0" :class="{ playing: Lget(currentSong, 'id', null) === Lget(song, 'id', null) }">{{ Lget(song, 'songName', '') }}</v-list-item-title>
              <v-list-item-subtitle class="subtitle-2 mt-n1 obscured-text">
                <router-link :to="{ name: 'Artist', params: {id: Lget(song, 'album.artist.id', 0)} }">{{ Lget(song, 'album.artist.artistName', '') }}</router-link>
                <span v-if = "Lget(song, 'album.artist.isVarious', false) && Lget(song, 'mp3Artist', '') !== '' && Lget(song, 'album.artist.artistName', false) !== Lget(song, 'mp3Artist', false)">
                  ({{ Lget(song, 'mp3Artist', '') }})
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </td>

        <td v-if="['playlist', 'playlist-owner', 'queue', 'recommended', 'likedsongs'].includes(mode)" width="20%" class="text-left"><router-link :to="{ name: 'Album', params: {id: Lget(song, 'album.id', 0)} }">{{ Lget(song, 'album.albumName', '') }}</router-link></td>
        <td v-if="['playlist', 'playlist-owner', 'likedsongs'].includes(mode)" width="20%" class="text-left">{{ (mode === 'likedsongs' ? song.likeStamp : song.PlaylistItem.createdAt) | datetime }}</td>
        <td v-if="mode==='album'" width="20%" class="text-right">{{ song.plays }}</td>
        <td v-if="mode==='artist'" width="20%" class="text-left">{{ song.timesPlayed }}</td>
        <td v-if="mode!=='recommended'" width="5%" class="text-right"><LikeIcon :id="Lget(song, 'id', null)" type="Song" :likes="Lget(song, 'likes', false)" :isHovering="hover==idx" :hideEmpty="true"></LikeIcon></td>
        <td v-if="mode!=='recommended'" width="5%" class="text-right">{{ Lget(song, 'mp3Minutes', 0) }}:{{ Lget(song, 'mp3Seconds', 0) | pad2digit }}</td>
        <td v-if="!['queue', 'recommended'].includes(mode)" width="70px" style="min-width: 70px !important;">
          <SongItemMenu
            :isArtist="mode==='artist'"
            :isPlaylist="['playlist', 'playlist-owner'].includes(mode)"
            :isPlaylistOwner="mode==='playlist-owner'"
            :isAlbum="mode==='album'" 
            :song="song" 
            :isHovering="hover==idx || markedRow===idx" 
            @clicked="markedRow=idx"
            @closed="markedRow = (markedRow===idx ? null : markedRow)">
          </SongItemMenu>
        </td>
        <td v-if="mode==='recommended'" width="70px">
          <v-btn rounded outlined @click="$emit('addTrack', song)">{{ $t('buttons.add') }}</v-btn>
        </td>
      </tr>
    </draggable>
  </v-simple-table>
  <EndlessScroll v-if="isEndless" @fetchMore="$emit('fetchData')" :gotAll="gotAll"/>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { mdiClockOutline } from '@mdi/js'
import draggable from 'vuedraggable'
import Lget from 'lodash/get'
import TrackNum from '@/components/TrackNum'
import LikeIcon from '@/components/LikeIcon'
import SongItemMenu from '@/components/SongItemMenu'
import EndlessScroll from '@/components/EndlessScroll'

export default {
  props: {
    songs: {
      type: Array,
      default: () => []
    },
    mode: {
      type: String,
      required: true
    },
    startTrackNumAt: {
      type: Number,
      default: 1
    },
    isEndless: {
      type: Boolean,
      default: false
    },
    gotAll: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    mdiClockOutline,
    hover: null,
    markedRow: null
  }),

  components: {
    TrackNum,
    LikeIcon,
    SongItemMenu,
    EndlessScroll,
    draggable
  },

  computed: {
    ...mapGetters('player', ['currentSong']),

    sortableSongs: {
      get() {
        return this.songs
      },
      set(value) {
        if (this.mode === 'playlist-owner') {
          this.resortPlaylist(value)
        }
      }
    }
  },

  methods: {
    ...mapActions('playlists', ['resortPlaylist']),

    Lget: Lget
  }
}
</script>

<style scoped>
  .playing {
    color: var(--v-primary-base) !important;
  }
  .song-table table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  thead, th {
    border-bottom: 1px solid rgba(255, 255, 255, 0.7) !important;
  }
  th {
    text-transform: uppercase !important;
  }
  .wrapper /deep/ .v-data-table__wrapper {
    overflow: unset;
  }
  .wrapper /deep/ thead.sticky {
    position: sticky;
    top: 48px;
    z-index: 1;
    background-color: var(--v-cardbackground-base) !important;
  }
  .cover-container {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
</style>