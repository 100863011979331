<template>
  <v-card class="detail-view foo" :style="`background-image: ${gradientBackground}`">
    <TopBar :isTransparent="atTop" :transparency="transparency" :showPlay="true" @play="$emit('play')" @pause="$emit('pause')">
      <template v-slot:title>{{ title }}</template>
    </TopBar>
    <v-container fluid class="view detail-view mb-12 pb-12 pt-12" :class="{'px-8': !$vuetify.breakpoint.mobile, 'px-0': $vuetify.breakpoint.mobile}" v-scroll="onScroll">
      <v-card flat class="detail-view-container transparent-background pt-6">
        <slot></slot>
      </v-card>
    </v-container>
  </v-card>
</template>

<script>
import Lget from 'lodash/get'
import LisEmpty from 'lodash/isEmpty'
import NavButtons from '@/components/NavButtons.vue'
import UserMenu from '@/components/UserMenu.vue'
import TopBar from '@/components/TopBar'
import { VibrantMixin } from '@/mixins'

export default {
  props: {
    title: String,
    imageSrc: String
  },

  data: () => ({
    atTop: true,
    transparency: 0
  }),

  components: {
    NavButtons,
    UserMenu,
    TopBar
  },

  mixins: [
    VibrantMixin
  ],

  computed: {
    albumArt() {
      const cover = Lget(this, 'imageSrc', null)
      const emptyCover = require("@/assets/images/album.png")
      return LisEmpty(cover) ? emptyCover : cover
    }
  },

  methods: {
    onScroll(e) {
      const scrollTop = Lget(e, 'srcElement.scrollingElement.scrollTop', 0)
      this.atTop = !Boolean(scrollTop > 350)
      this.transparency = (scrollTop > 350) ? 1 : (scrollTop / 350)
    }
  }
}
</script>

<style scoped>
</style>
